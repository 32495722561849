import ApiRequest from '@/pure/api-requests'
import { decodeCashbackComplain, decodeStatusUpdate } from '@/pure/api-parser'

import {
  cashbackComplains as requestCashbackComplains,
  statusModifiers as requestStatusModifiers,
  statusModifierEdit as requestStatusModifierEdit
} from '../../../graphql-sdl/requests-backoffice.graphql'

const apiRequest = new ApiRequest()

const state = {
  isLoadingComplains: false,
  isLoadingStatusUpdates: false,
  isLoadingEditStatusModifier: false,

  // All cashback complains and status update requests
  cashbackComplainsRequests: [],
  statusUpdateRequests: []
}

/** @type import('vuex/types').Module<state, any> */
const module = {
  namespaced: true,

  state,

  actions: {
    loadCashbackComplainsRequests (context, { clubistId = null }) {
      const payload = {}

      if (clubistId) {
        payload.clubistId = clubistId
      }

      context.commit('setIsLoadingComplains', true)

      return apiRequest.query(requestCashbackComplains, payload)
        .then(({ cashbackComplains }) => {
          if (cashbackComplains) {
            return cashbackComplains.map(decodeCashbackComplain)
          }

          return []
        })
        .then((decodedCashbackComplainsRequests) => {
          if (!clubistId) {
            context.commit('setCashbackComplainsRequests', decodedCashbackComplainsRequests)
          }

          return decodedCashbackComplainsRequests
        })
        .finally(() => {
          context.commit('setIsLoadingComplains', false)
        })
    },

    loadStatusUpdateRequests (context, { clubistId = null }) {
      const payload = {
        statusModifierFilter: {}
      }

      if (clubistId) {
        payload.statusModifierFilter.clubistId = clubistId
      }

      context.commit('setIsLoadingStatusUpdates', true)

      return apiRequest.query(requestStatusModifiers, payload)
        .then(({ statusModifiers }) => {
          if (statusModifiers) {
            return statusModifiers.map(decodeStatusUpdate)
          }

          return []
        })
        .then((decodedStatusUpdateRequests) => {
          if (!clubistId) {
            context.commit('setStatusUpdateRequests', decodedStatusUpdateRequests)
          }

          return decodedStatusUpdateRequests
        })
        .finally(() => {
          context.commit('setIsLoadingStatusUpdates', false)
        })
    },

    editStatusModifier (context, { state = '', clubistId = null, statusRequestId = null }) {
      const payload = {
        statusRequestEdit: {
          state,
          clubistId,
          statusRequestId
        }
      }

      context.commit('setIsLoadingEditStatusModifier', true)

      return apiRequest.mutation(requestStatusModifierEdit, payload)
        .then(() => {
          context.commit('setIsLoadingEditStatusModifier', false)
          return context.dispatch('loadStatusUpdateRequests', { clubistId })
        })
        .catch(() => {
          context.commit('setIsLoadingEditStatusModifier', false)
        })
    }
  },

  mutations: {
    setIsLoadingComplains (state, payload) {
      state.isLoadingComplains = payload
    },

    setIsLoadingStatusUpdates (state, payload) {
      state.isLoadingStatusUpdates = payload
    },

    setIsLoadingEditStatusModifier (state, payload) {
      state.isLoadingEditStatusModifier = payload
    },

    setCashbackComplainsRequests (state, payload) {
      state.cashbackComplainsRequests = payload
    },

    removeCashbackComplainsRequests (state) {
      state.cashbackComplainsRequests = []
    },

    setStatusUpdateRequests (state, payload) {
      state.statusUpdateRequests = payload
    },

    removeStatusUpdateRequests (state) {
      state.statusUpdateRequests = []
    }
  },

  getters: {
    isLoading: state => state.isLoadingComplains || state.isLoadingStatusUpdates,
    isLoadingEditStatusModifier: state => state.isLoadingEditStatusModifier,

    cashbackComplainsRequests: state => state.cashbackComplainsRequests,
    statusUpdateRequests: state => state.statusUpdateRequests
  }
}

export default module
