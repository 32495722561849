const BOOLEAN_ENUM = new Map([
  [true, 'TRUE'],
  [false, 'FALSE'],
  [null, 'NULL']
])

const LANGUAGES_ENUM = new Map([
  ['fr', 'FR'],
  ['ar', 'AR']
])

const CIVILITY_ENUM = new Map([
  ['female', 'MRS'],
  ['male', 'M']
])

const OPERATION_ENUM = new Map([
  ['credit', 'CREDIT'],
  ['debit', 'DEBIT']
])

const STATE_ENUM = new Map([
  ['expired', 'EXPIRED'],
  ['pending', 'PENDING'],
  ['refused', 'REFUSED'],
  ['reset', 'RESET'],
  ['validated', 'VALIDATED'],
  ['completed', 'COMPLETED'],
  ['processing', 'PROCESSING']
])

const USER_TYPE = new Map([
  ['clubist', 'CLUBIST'],
  ['callCenter', 'CALLCENTER'],
  ['marketing', 'MARKETING'],
  ['monetic', 'MONETIC'],
  ['director', 'DIRECTOR']
])

const STATUS = new Map([
  [0, 'NORMAL'],
  [1, 'WARNED'],
  [2, 'BANNED']
])

/**
 * Return key of map from value
 **/
const getKey = (map, value) => {
  const key = ([...map.entries()])
    .filter(({ 1: v }) => v === value)
    .map(([k]) => k)[0]

  if (key !== undefined) {
    return key
  } else {
    return null
  }
}

export const encodeRegister1 = (clubist, recaptcha) => {
  const required = ['lastName', 'cardNum']
  required.forEach(label => {
    if (clubist[label] === null || clubist[label] === undefined) {
      throw new Error(label + ' required to register step 1')
    }
  })
  if (recaptcha === null || recaptcha === undefined) {
    throw new Error('recaptcha required to register step 1')
  }

  return {
    idClubCard: clubist.cardNum,
    lastName: clubist.lastName,
    recaptcha
  }
}

export const encodeRegister2 = (clubist) => {
  const required = ['isOptinSms', 'isOptinEmail', 'civility', 'lastName', 'firstName', 'cardNum', 'language']
  required.forEach(label => {
    if (clubist[label] === null || clubist[label] === undefined) {
      throw new Error(label + ' required to register step 2')
    }
  })

  let optinSms
  if (BOOLEAN_ENUM.has(clubist.isOptinSms)) {
    optinSms = BOOLEAN_ENUM.get(clubist.isOptinSms)
  }

  let optinEmail
  if (BOOLEAN_ENUM.has(clubist.isOptinEmail)) {
    optinEmail = BOOLEAN_ENUM.get(clubist.isOptinEmail)
  }

  let language
  if (LANGUAGES_ENUM.has(clubist.language)) {
    language = LANGUAGES_ENUM.get(clubist.language)
  }

  let title
  if (CIVILITY_ENUM.has(clubist.civility)) {
    title = CIVILITY_ENUM.get(clubist.civility)
  }

  return {
    title,
    language,
    optinEmail,
    optinSms,
    idClubCard: clubist.cardNum,
    addressCity: clubist.city || undefined,
    birthDate: clubist.birthDate || undefined,
    email: clubist.email || undefined,
    firstName: clubist.firstName,
    isTaxi: clubist.isTaxi,
    lastName: clubist.lastName,
    phoneNumber: clubist.phone
  }
}

export const encodeRegister3 = (clubist) => {
  const required = ['isOptinSms', 'isOptinEmail', 'civility', 'lastName', 'firstName', 'cardNum', 'language', 'password']
  required.forEach(label => {
    if (clubist[label] === null || clubist[label] === undefined) {
      throw new Error(label + ' required to register step 3')
    }
  })

  let optinSms
  if (BOOLEAN_ENUM.has(clubist.isOptinSms)) {
    optinSms = BOOLEAN_ENUM.get(clubist.isOptinSms)
  }

  let optinEmail
  if (BOOLEAN_ENUM.has(clubist.isOptinEmail)) {
    optinEmail = BOOLEAN_ENUM.get(clubist.isOptinEmail)
  }

  let language
  if (LANGUAGES_ENUM.has(clubist.language)) {
    language = LANGUAGES_ENUM.get(clubist.language)
  }

  let title
  if (CIVILITY_ENUM.has(clubist.civility)) {
    title = CIVILITY_ENUM.get(clubist.civility)
  }

  return {
    title,
    language,
    optinEmail,
    optinSms,
    idClubCard: clubist.cardNum,
    addressCity: clubist.city || undefined,
    birthDate: clubist.birthDate || undefined,
    email: clubist.email || undefined,
    firstName: clubist.firstName,
    isTaxi: clubist.isTaxi,
    lastName: clubist.lastName,
    phoneNumber: clubist.phone,
    password: clubist.password
  }
}

export const decodePrePaidCardData = (prePaidCard) => {
  return {
    id: prePaidCard.prePaidCardId,
    archiveDate: prePaidCard.archiveDate
  }
}

export const decodeFile = file => {
  const fileExist = file && (file.name || file.type || file.storageId || file.storageUploadUrl)
  return !fileExist ? null : {
    name: file.name ? file.name.split('/').pop() : 'unknown-filename',
    path: file.name,
    type: file.type,
    storageId: file.storageId,
    storageUploadUrl: file.storageUploadUrl
  }
}

export const encodeFile = file => {
  if (!file) {
    return null
  }

  return {
    name: file.path,
    type: file.type,
    storageId: file.storageId
  }
}

export const decodeCashbackComplain = (cashbackComplain) => {
  const payload = {
    id: cashbackComplain.cashbackComplainId,
    message: cashbackComplain.message,
    date: cashbackComplain.createdDate,
    state: getKey(STATE_ENUM, cashbackComplain.state),
    file: decodeFile(cashbackComplain.file)
  }

  if (cashbackComplain.clubist) {
    if (cashbackComplain.clubist.clubistId) {
      payload.clubistId = cashbackComplain.clubist.clubistId
    }
    if (cashbackComplain.clubist.clubCard) {
      payload.cardNum = cashbackComplain.clubist.clubCard.clubCardId
    }
    if (cashbackComplain.clubist.lastName) {
      payload.lastName = cashbackComplain.clubist.lastName
    }
    if (cashbackComplain.clubist.firstName) {
      payload.firstName = cashbackComplain.clubist.firstName
    }
    if (cashbackComplain.amount) {
      payload.amount = cashbackComplain.amount
    }
  }

  return payload
}

export const decodeStatusUpdate = (statusUpdate) => {
  const payload = {
    id: null,
    statusRequestId: null,
    clubistId: null,
    cardNum: null,
    firstName: null,
    lastName: null,
    phoneNumber: null,
    oldStatus: null,
    newStatus: null,
    date: null,
    description: null,
    state: null
  }

  if (statusUpdate.clubist) {
    if (statusUpdate.clubist.clubistId) {
      payload.id = statusUpdate.clubist.clubistId
      payload.clubistId = statusUpdate.clubist.clubistId
    }

    if (statusUpdate.clubist.firstName) {
      payload.firstName = statusUpdate.clubist.firstName
    }

    if (statusUpdate.clubist.lastName) {
      payload.lastName = statusUpdate.clubist.lastName
    }

    if (statusUpdate.clubist.phoneNumber) {
      payload.phoneNumber = statusUpdate.clubist.phoneNumber
    }

    if (statusUpdate.clubist.clubCard) {
      if (statusUpdate.clubist.clubCard.clubCardId) {
        payload.cardNum = statusUpdate.clubist.clubCard.clubCardId
      }
    }
  }

  if (statusUpdate.statusRequest) {
    if (statusUpdate.statusRequest.statusRequestId) {
      payload.statusRequestId = statusUpdate.statusRequest.statusRequestId
    }

    if (statusUpdate.statusRequest.date) {
      payload.date = statusUpdate.statusRequest.date
    }

    if (statusUpdate.statusRequest.message) {
      payload.description = statusUpdate.statusRequest.message
    }

    if (statusUpdate.statusRequest.previousStatus) {
      payload.oldStatus = statusUpdate.statusRequest.previousStatus
    }

    if (statusUpdate.statusRequest.status) {
      payload.newStatus = statusUpdate.statusRequest.status
    }

    if (statusUpdate.statusRequest.file) {
      payload.file = decodeFile(statusUpdate.statusRequest.file)
    }
  }

  if (statusUpdate.state) {
    payload.state = getKey(STATE_ENUM, statusUpdate.state)
  }

  return payload
}

export const decodeStationData = (station) => {
  const required = []
  required.forEach(label => {
    if (station[label] === null || station[label] === undefined) {
      throw new Error(label + ' required to init station data')
    }
  })

  return {
    id: station.pickupStationId,
    name: station.name,
    street: station.address,
    complement: station.addressComplement,
    city: station.addressCity,
    district: station.addressDistrict,
    zipcode: station.addressZipcode
  }
}

export const decodeMediaRequestData = (mediaRequest) => {
  const required = []
  required.forEach(label => {
    if (mediaRequest[label] === null || mediaRequest[label] === undefined) {
      throw new Error(label + ' required to init mediaRequest data')
    }
  })

  return {
    clubistId: mediaRequest.clubistId,
    startDate: mediaRequest.startDate,
    endDate: mediaRequest.endDate,
    category: mediaRequest.mediaContent
  }
}

export const encodeClubistDataFlexible = (clubist) => {
  let optinSms
  if (BOOLEAN_ENUM.has(clubist.isOptinSms)) {
    optinSms = BOOLEAN_ENUM.get(clubist.isOptinSms)
  }

  let optinEmail
  if (BOOLEAN_ENUM.has(clubist.isOptinEmail)) {
    optinEmail = BOOLEAN_ENUM.get(clubist.isOptinEmail)
  }

  let stationId
  if (clubist.station && clubist.station.id) {
    stationId = clubist.station.id
  }

  let title
  if (CIVILITY_ENUM.has(clubist.civility)) {
    title = CIVILITY_ENUM.get(clubist.civility)
  }

  let language
  if (LANGUAGES_ENUM.has(clubist.language)) {
    language = LANGUAGES_ENUM.get(clubist.language)
  }

  let statusRequest
  if (clubist.statusRequest && STATUS.has(clubist.statusRequest.status)) {
    statusRequest = {
      status: STATUS.get(clubist.statusRequest.status),
      message: clubist.statusRequest.message,
      file: encodeFile(clubist.statusRequest.file)
    }
  }

  const encoded = {
    optinSms,
    optinEmail,
    stationId,
    clubistId: clubist.id,
    title,
    language,
    statusRequest,
    firstName: clubist.firstName,
    addressCity: clubist.city,
    lastName: clubist.lastName,
    phoneNumber: clubist.phone,
    email: clubist.email,
    prePaidCardId: clubist.prePaidCardId,
    cashback: clubist.cashback,
    birthDate: clubist.birthDate,
    isTaxi: clubist.isTaxi,
    idClubCard: clubist.cardNum,
    drawAlert: clubist.drawAlert,
    winAlert: clubist.winAlert
  }

  // Remove unusued keys
  Object.keys(encoded).forEach(label => {
    if (encoded[label] === undefined) {
      delete encoded[label]
    }
  })

  return encoded
}

export const encodeClubistData = (clubist) => {
  const required = ['id'] // ['id', 'cardNum'] // isOptinEmail and isOptinSms required but can be null
  required.forEach(label => {
    if (clubist[label] === null || clubist[label] === undefined) {
      throw new Error(label + ' required to init clubist data')
    }
  })

  return encodeClubistDataFlexible(clubist)
}

const decodeClubistCashback = cashbackUnlockClaim => {
  return {
    id: cashbackUnlockClaim.cashbackId,
    cashback: cashbackUnlockClaim.amount || 0,
    isTriggeredBy: getKey(USER_TYPE, cashbackUnlockClaim.isTriggeredBy),
    state: cashbackUnlockClaim.state
  }
}

export const decodeClubCardData = clubCard => {
  const clubCardData = {
    cardNum: clubCard.clubCardId,
    city: clubCard.addressCity,
    birthDate: clubCard.birthDate,
    email: clubCard.email,
    firstName: clubCard.firstName,
    lastName: clubCard.lastName,
    isTaxi: clubCard.isTaxi,
    isOptinSms: getKey(BOOLEAN_ENUM, clubCard.optinSms || null),
    isOptinEmail: getKey(BOOLEAN_ENUM, clubCard.optinEmail || null),
    phone: clubCard.phoneNumber,
    isCar: clubCard.profile === 'CAR',
    isTruck: clubCard.profile === 'TRUCK',
    statusID: getKey(STATUS, clubCard.status || 'NORMAL'),
    civility: getKey(CIVILITY_ENUM, clubCard.title)
  }

  Object.keys(clubCardData).forEach(key => {
    if (clubCardData[key] === null) {
      delete clubCardData[key]
    }
  })

  return clubCardData
}

export const decodeUserData = user => {
  const roleList = user.role && Array.isArray(user.role) ? user.role : []
  const data = {
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    role: roleList.map(role => getKey(USER_TYPE, role)),
    isClubist: roleList.indexOf('CLUBIST') > -1,
    isCallCenter: roleList.indexOf('CALLCENTER') > -1,
    isMarketing: roleList.indexOf('MARKETING') > -1,
    isMonetic: roleList.indexOf('MONETIC') > -1,
    isDirector: roleList.indexOf('DIRECTOR') > -1,
    isAdministrator: roleList.indexOf('ADMINISTRATOR') > -1
  }

  // Get clubistId from first email part
  if (data.isClubist) {
    data.clubistId = (data.email || '').split('@')[0] || null
  }

  return data
}

export const decodeClubistDataFlexible = (clubist) => {
  return {
    id: clubist.clubistId,
    civility: getKey(CIVILITY_ENUM, clubist.title),
    firstName: clubist.firstName,
    city: clubist.addressCity,
    lastName: clubist.lastName,
    cardNum: clubist.clubCard ? clubist.clubCard.clubCardId : null,
    cashbackAlert: clubist.cashback && clubist.cashback.dueDate && clubist.cashback.dueDate.dueDate ? clubist.cashback.dueDate.dueDate : null,
    cashbackAlertAmount: clubist.cashback && clubist.cashback.dueDate && clubist.cashback.dueDate.amount ? clubist.cashback.dueDate.amount : null,
    prePaidCardClaimed: clubist.prePaidCardClaimed ? clubist.prePaidCardClaimed.prePaidCardClaimedId : null,
    isPrepaidCardClaimOnDelivery: clubist.prePaidCardClaimed ? clubist.prePaidCardClaimed.state === 'PENDING' : false,
    hasAlreadyClaimFirstPrepaidCard: !!clubist.hasAlreadyClaimPrepaidCard,
    hasAlreadyClaimPrepaidCard: !!clubist.prePaidCardClaimed,
    prepaidCardNum: clubist.prePaidCard ? clubist.prePaidCard.prePaidCardId : null,
    cashbackUnlockClaim: clubist.cashback && clubist.cashback.unlockClaim ? decodeClubistCashback(clubist.cashback.unlockClaim) : null,
    phone: clubist.phoneNumber,
    email: clubist.email,
    statusID: getKey(STATUS, clubist.status || 'NORMAL'),
    cashback: (clubist.cashback && clubist.cashback.amount) || 0,
    birthDate: clubist.birthDate,
    isTaxi: clubist.isTaxi,
    isWinAllowed: clubist.winAllow,
    isCar: clubist.profile === 'CAR',
    isTruck: clubist.profile === 'TRUCK',
    language: getKey(LANGUAGES_ENUM, clubist.language),
    isOptinSms: getKey(BOOLEAN_ENUM, clubist.optinSms || null),
    isOptinEmail: getKey(BOOLEAN_ENUM, clubist.optinEmail || null),
    drawAlert: clubist.drawAlert,
    winAlert: clubist.winAlert,
    dirhamsExtracting: false
  }
}

export const decodeClubistData = (clubist) => {
  const required = []
  required.forEach(label => {
    if (clubist[label] === null || clubist[label] === undefined) {
      throw new Error(label + ' required to init clubist data')
    }
  })

  return decodeClubistDataFlexible(clubist)
}

export const decodeLottery = (lottery) => {
  let clubist = null
  if (lottery.clubist) {
    clubist = decodeClubistDataFlexible(lottery.clubist)
  } else if (lottery.clubCard) {
    clubist = decodeClubCard(lottery.clubCard)
    clubist.cardNum = clubist.id
    delete clubist.id
  } else {
    clubist = {
      firstName: lottery.firstName || '',
      lastName: lottery.lastName || ''
    }
  }

  return {
    clubist,
    drawDate: lottery.drawDate,
    startDate: lottery.startDate,
    endDate: lottery.endDate,
    id: lottery.cashbackDetail ? lottery.cashbackDetail.transactionId : undefined,
    date: lottery.cashbackDetail ? lottery.cashbackDetail.transactionDate : undefined,
    nature: lottery.cashbackDetail ? lottery.cashbackDetail.product.toUpperCase() : undefined,
    gamme: lottery.cashbackDetail ? lottery.cashbackDetail.productName : undefined,
    liters: lottery.cashbackDetail ? lottery.cashbackDetail.quantity : undefined,
    dhs: lottery.cashbackDetail ? lottery.cashbackDetail.amount : undefined,
    station: lottery.cashbackDetail && lottery.cashbackDetail.station ? lottery.cashbackDetail.station.stationName : undefined,
    city: lottery.cashbackDetail && lottery.cashbackDetail.station ? lottery.cashbackDetail.station.stationCity : undefined
  }
}

const decodeClubCard = clubCard => {
  return {
    id: clubCard.clubCardId,
    city: clubCard.addressCity,
    archiveDate: clubCard.archiveDate,
    birthDate: clubCard.birthDate,
    email: clubCard.email,
    firstName: clubCard.firstName,
    lastName: clubCard.lastName,
    phone: clubCard.phoneNumber,
    civility: getKey(CIVILITY_ENUM, clubCard.title)
  }
}

export const decodeHistoryCashback = (history) => {
  const operation = getKey(OPERATION_ENUM, history.operation)
  const amount = history.amount * (operation === 'debit' ? -1 : 1)
  return {
    id: history.cashbackId,
    amount,
    archiveDate: history.archiveDate,
    createdDate: history.createdDate,
    updatedDate: history.updatedDate,
    complaint: {
      message: history.comment,
      file: null
    },
    operationType: history.operationType, // BLOCK CLAIM REACTIVATION TRANSACTION UNLOCK
    author: getKey(USER_TYPE, history.isTriggeredBy),
    state: history.state,
    isPending: history.state === 'PENDING',
    isReset: history.state === 'RESET',
    isValidated: history.state === 'VALIDATED' && history.operationType !== 'EXPIRED' && history.operationType !== 'RESET',
    isExpired: history.state === 'EXPIRED' || history.operationType === 'EXPIRED' || history.operationType === 'RESET',
    isRefused: history.state === 'REFUSED',
    clubCard: history.clubCard ? decodeClubCard(history.clubCard) || null : null
  }
}

export const decodeHistoryTransaction = (transaction) => {
  const clubCard = transaction.clubCard ? decodeClubCard(transaction.clubCard) || null : null
  let statusID
  if (transaction.status) {
    statusID = getKey(STATUS, transaction.status)
  } else if (clubCard) {
    statusID = clubCard.statusID
  } else {
    statusID = getKey(STATUS, 'NORMAL')
  }

  let station = {
    id: transaction.station.stationId,
    name: transaction.station.stationName
  }

  return {
    id: transaction.transactionId,
    amount: transaction.amount,
    product: transaction.productName,
    nature: transaction.nature,
    date: transaction.transactionDate,
    volume: transaction.volume,
    station,
    statusID,
    clubCard
  }
}

export const decodeHistoryEvents = (event) => {
  let station = null
  let media = null
  let clubCard = null
  let clubist = null

  // Get station if station props sended by API
  const hasStation = event.extra && event.extra.prepaidCardClaim && ['stationId', 'name', 'addressCity'].find(key => event.extra.prepaidCardClaim[key])
  if (hasStation) {
    station = decodeStationData(event.extra.prepaidCardClaim)
  }

  // Get media if media props sended by API
  const hasMedia = event.extra && event.extra.mediaRequest && ['startDate', 'endDate', 'mediaContent'].find(key => event.extra.mediaRequest[key])
  if (hasMedia) {
    media = decodeMediaRequestData(event.extra.mediaRequest)
  }

  // Get clubcard ID if clubcard props sended by API
  const hasClubcard = event.extra && event.extra.clubCard && event.extra.clubCard.clubCardId
  if (hasClubcard) {
    clubCard = decodeClubCardData(event.extra.clubCard)
  }

  // Get clubcard ID if clubcard props sended by API
  const hasClubist = event.extra && event.extra.clubist && event.extra.clubist
  if (hasClubist) {
    clubist = decodeClubistDataFlexible(event.extra.clubist)
  }

  return {
    date: event.createdDate,
    operator: event.operator ? decodeUserData(event.operator) || null : null,
    clubist: event.clubist ? decodeClubCard(event.clubist) || null : null,
    action: event.action,
    property: event.property,

    type: 'events.' + event.action + '.' + event.property + '.type',
    detail: 'events.' + event.action + '.' + event.property + '.detail',
    extra: {
      station,
      media,
      clubCard,
      clubist
    }
  }
}

export const decodeMedia = (media) => {
  return {
    id: media.mediaId,
    date: media.createdDate,
    downloaded: media.downloadedDate,
    category: media.category,
    // state: getKey(STATE_ENUM, media.state),
    rows: {
      processed: media.rows.processed,
      total: media.rows.total
    },
    isTodo: !media.downloadedDate, // CTA
    isDownloaded: !!media.downloadedDate, // date à la place du CTA
    isPending: (media.downloadedDate && media.rows.processed === 0) && media.state !== 'VALIDATED', // traitement en cours
    isOk: media.rows.processed >= media.rows.total || media.state === 'VALIDATED', // green
    isKo: media.rows.processed > 0 && media.rows.processed < media.rows.total, // red

    file: decodeFile(media.file)
  }
}

export const decodeGaugeHistory = (history) => {
  return {
    id: history.transactionId,
    amount: history.amount,
    cashback: history.cashback,
    clubCard: history.clubCard ? decodeClubCard(history.clubCard) || null : null,
    date: history.createdDate,
    isTaxi: history.isTaxi,
    isTriggeredBy: history.isTriggeredBy ? history.isTriggeredBy.toUpperCase() : '',
    isoWeek: history.isoWeek,
    multiplier: history.multiplicator,
    product: history.product,
    productCode: history.productCode,
    productName: history.productName,
    productType: history.productType,
    profile: history.profile,
    quantity: history.quantity,
    quantityConsidered: history.quantityConsidered,
    quantityRemaining: history.quantityRemaining,
    quota: history.quota,
    station: history.station && history.station.name ? history.station.stationName : '',
    status: history.status,
    transactionDate: history.transactionDate,
    typename: history.__typename
  }
}

export const decodeMediaPrepaidCard = (media) => {
  return {
    date: media ? media.createdDate || new Date().toISOString() : null,
    file: media ? decodeFile(media.file) : null,
    category: media ? media.mediaContent || '' : null
  }
}
