import { phoneRuleDisabled } from '@/pure/env'

const excludeArabicCharsPattern = /^[^\u0621-\u064A]+$/
const latinPattern = /^(?:(?![×÷])[ \-'aA-zZÀ-ÿ])+$/
const getTruncatedDate = (date, addYear = 0) => {
  return new Date(date.getFullYear() + addYear, date.getMonth(), date.getDate(), 0, 0, 0, 0)
}
const majorityBirthDay = getTruncatedDate(new Date(Date.now()), -18)
const maxBirthDay = getTruncatedDate(new Date(Date.now()), -130)

export const createRequired = (name, { trigger = 'blur', message = 'Champ ' + name + ' requis' } = {}) => {
  return {
    required: true,
    message,
    trigger
  }
}

export const birthDateRule = [
  {
    validator: (rule, value, callback) => {
      if (value === '') {
        callback()
      } else if (getTruncatedDate(new Date(value)).getTime() > majorityBirthDay.getTime()) {
        callback(new Error('Le clubiste doit être majeur'))
      } else {
        callback()
      }
    },
    trigger: 'blur'
  }
]

export const formatMarocPhone = (phone) => {
  if (phone.length > 0 && phone[0] === '0' && !phoneRuleDisabled()) {
    return '212' + phone.substr(1, phone.length)
  }

  return phone
}

export const formatPartFrDate = (frDateStr = '', lastFrDateStr = '') => {
  if (frDateStr === null || frDateStr === '') {
    return frDateStr
  }

  let value = frDateStr

  // Write
  if (lastFrDateStr === null || frDateStr.length > lastFrDateStr.length) {
    // Auto add /
    if (value.length > 1 && value.length < 6 && value[value.length - 1] !== '/' && value[value.length - 2] !== '/') {
      value += '/'
    }

    // Disable long numbers
    if (value.split('/').length > 1) {
      value = value.split('/')
        .map((n, i) => {
          const length = i === 2 ? 4 : 2
          return n.substr(0, n.length + (length - n.length))
        })
        .join('/')
    }
  }

  return value
}

export const stdDateToFrDate = (stdDateStr) => {
  if (isNaN((new Date(stdDateStr)).getTime())) {
    throw new Error('Date invalide')
  }
  return (new Date(stdDateStr)).toLocaleDateString('fr-FR')
}

export const frDateToStdDate = (frDateStr) => {
  const dateEnArr = (frDateStr || '').split('/')
  if (dateEnArr.length !== 3) {
    throw new Error('Date invalide')
  } else {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }

    const dateEn = new Date(dateEnArr[2] + '/' + dateEnArr[1] + '/' + dateEnArr[0])
      .toLocaleDateString('fr-FR', options)
      .split('/')
      .reverse()
      .join('-')

    return dateEn
  }
}

export const birthDateFrRule = [
  {
    validator: (rule, dateFr, callback) => {
      if (dateFr !== '' && dateFr !== null) {
        try {
          const dateEn = frDateToStdDate(dateFr)
          if (getTruncatedDate(new Date(dateEn)).getTime() > majorityBirthDay.getTime()) {
            return callback(new Error('Le clubiste doit être majeur'))
          }
          if (getTruncatedDate(new Date(dateEn)).getTime() < maxBirthDay.getTime()) {
            return callback(new Error('Age invalide'))
          }
        } catch (error) {
          callback(error)
        }
      }

      callback()
    },
    trigger: 'blur'
  }
]

export const phoneRule = phoneRuleDisabled() ? [] : [
  {
    message: `Le chiffre 0 n'est pas accepté après l'indicatif`,
    pattern: /^(?!2120).*/
  },
  {
    message: 'Le téléphone doit commencer par 212',
    pattern: /^212/
  },
  {
    message: 'Le téléphone doit comporter 12 chiffres',
    pattern: /^[0-9]{12}$/
  }
]

export const cashbackRule = [
  {
    message: 'Montant incorrect',
    pattern: /^[0-9]+$/
  }
]

export const passwordRule = [
  {
    validator: (rule, value, callback) => {
      if (value.length < 8) {
        callback(new Error('Le mot de passe doit contenir minimum 8 caractères'))
      } else {
        callback()
      }
    }
  },
  {
    message: 'Le mot de passe doit contenit au moins un chiffre',
    pattern: /[0-9]+/
  },
  {
    message: 'Le mot de passe doit contenit au moins une lettre majuscule',
    pattern: /[A-Z]+/
  },
  {
    message: 'Le mot de passe doit contenit au moins une lettre minuscule',
    pattern: /[a-z]+/
  }
]

export const emailRule = [
  {
    message: 'Adresse email invalide',
    trigger: 'blur',
    pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // Vee-validate email regex
  },
  {
    message: 'Adresse email invalide',
    trigger: 'blur',
    pattern: excludeArabicCharsPattern // Arabic chars
  }
]

export const clubCardNumRule = [
  {
    message: 'Numéro de carte invalide',
    trigger: 'blur',
    pattern: /^[0-9]{19}$/
  }
]

export const zipCodeRule = [
  {
    message: 'Code postal invalide',
    trigger: 'blur',
    pattern: /^[0-9]{5}$/
  }
]

export const totalCardNumRule = [
  {
    message: 'Numéro de carte invalide',
    trigger: 'blur',
    pattern: /^[0-9]{6}$/
  }
]

export const lastnameRule = [
  {
    message: 'Nom de famille invalide',
    pattern: latinPattern
  }
]

export const firstnameRule = [
  {
    message: 'Prénom invalide',
    pattern: latinPattern
  }
]

export const cityRule = [
  {
    message: 'Nom de ville invalide',
    pattern: latinPattern
  }
]
