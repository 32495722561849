import '@babel/polyfill'
import 'whatwg-fetch'
import 'intersection-observer'
import 'minireset.css'
import '@/scss/element-ui/index.scss'
import '@/scss/app.scss'
import Vue from 'vue'
import i18n from './locale/i18n'
import App from './App.vue'
import router from './router'
import store from './store'
import ButtonLink from './components/ButtonLink'
import RoundBadge from './components/RoundBadge'
import './sentry'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/fr'
import { version } from '../package.json'

Vue.use(ElementUI, { locale })
Vue.component('button-link', ButtonLink)
Vue.component('round-badge', RoundBadge)

Vue.config.productionTip = false

// Log current version
console.log('Client version', version)

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
