<template>
  <header class="site-header">
    <div class="container">
      <div class="header-logo">
        <router-link :to="mainPage">
          <img src="@/assets/images/total-energies-logo.png" alt width="90" height="72"/>
        </router-link>
      </div>

      <h1>{{ title }}</h1>

      <el-menu :default-active="activeLink" class="el-menu-header" mode="horizontal" :router="true">
        <el-menu-item v-if="isCC" :index="links[0]" class="center">
          Clubistes
        </el-menu-item>

        <el-menu-item v-if="isMkt" :index="links[1]" class="center">
          Demandes<br>Call Center
        </el-menu-item>

        <el-menu-item v-if="isCC" :index="links[2]" class="center">
          Tirage au sort
        </el-menu-item>

        <el-menu-item v-if="isDir" :index="links[3]" class="center">
          Envoi de<br>TotalEnergies card
        </el-menu-item>

        <el-menu-item v-if="isMon" :index="links[4]" class="center">
          Demandes à traiter
        </el-menu-item>

        <el-menu-item v-if="isDir" :index="links[5]" class="center">
          Export<br />Événements
        </el-menu-item>

        <el-menu-item v-if="isAdmin" :index="links[6]" class="center">
          Admin
        </el-menu-item>
      </el-menu>
      <el-dropdown @command="handleCommand">
        <span data-cy="user-menu" class="el-dropdown-link">
          {{ fullName }}

          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>

        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="logout" data-cy="sign-out">Déconnexion</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </header>
</template>

<script>
import { routes } from '../router'

const getPathByRoute = (name, childName = null) => {
  const route = routes.find(route => route.name === name)
  if (childName) {
    const child = route.children.find(route => route.name === childName)
    return route.path + '/' + child.path
  } else {
    return route.path
  }
}

export default {
  data () {
    return {
      isLoading: false,
      activeLink: null,
      links: [
        getPathByRoute('search'),
        getPathByRoute('cCRequests', 'cCRequestsCashbackClaim'),
        getPathByRoute('lottery'),
        getPathByRoute('sendPrepaidCard'),
        getPathByRoute('monetic', 'createTotalCard'),
        getPathByRoute('exportsEvenementiels'),
        getPathByRoute('admin')
      ]
    }
  },
  computed: {
    fullName () {
      return this.$store.state.user ? this.$store.state.user.firstName + ' ' + this.$store.state.user.lastName : ''
    },
    mainPage () {
      if (this.$store.getters.isMonetic) {
        return { name: 'createTotalCard' }
      }

      return { name: 'search' }
    },
    title () {
      if (this.$store.getters.isCallCenter) {
        return 'TotalEnergies Club | Centre de contact Client'
      } else if (this.$store.getters.isMarketing) {
        return 'TotalEnergies Club | Back Office Marketing'
      } else if (this.$store.getters.isMonetic) {
        return 'TotalEnergies Club | Back Office Monétique'
      }

      return 'TotalEnergies Club | Backoffice'
    },
    isMkt () {
      return this.$store.getters.isMarketing
    },
    isCC () {
      return this.$store.getters.isCallCenter
    },
    isMon () {
      return this.$store.getters.isMonetic
    },
    isDir () {
      return this.$store.getters.isDirector
    },
    isAdmin () {
      return this.$store.getters.isAdministrator
    }
  },

  watch: {
    $route: 'refreshActiveLink'
  },

  created () {
    this.refreshActiveLink()
  },

  methods: {
    refreshActiveLink () {
      // Set activeLink by current route to display current menu selected
      this.activeLink = this.$route && this.$route.meta && this.$route.meta.menuIndex !== undefined ? this.links[this.$route.meta.menuIndex] : null
    },

    handleCommand (command) {
      if (command === 'logout') {
        this.logout()
      }
    },

    logout () {
      this.isLoading = true

      this.$store.dispatch('logout')
        .then(() => this.$router.push({ name: 'home' }))
        .catch(error => this.$message.error(this.$t(error.message)))
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.site-header {
  height: 90px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  flex-shrink: 0;
  background-color: #fff;
  padding: 0 25px;
  border-bottom: 1px solid #e5e2e6;
  z-index: 1;
  .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  h1 {
    font-size: 20px;
    color: #606266;
    letter-spacing: 0.31px;
    margin: 0 50px;
    white-space: nowrap;
    font-weight: 400;
  }
  .el-dropdown {
    color: #606266;
    white-space: nowrap;
    border-bottom: 2px solid #fff;
    cursor: pointer;
  }
}

.el-menu--horizontal {
  display: flex;
  align-items: center;
  .el-menu-item {
    height: 90px;
    float: none;
    line-height: normal;
    display: flex;
    align-items: center;
  }
}

.center {
  text-align: center;
}
</style>
