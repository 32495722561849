import errorsCodes from '../../graphql-sdl/errors_codes_fr.json'
import actionEvents from '../../graphql-sdl/events_actions_fr.json'

export default {
  gql: errorsCodes,
  tables: {
    column: {
      lastName: `Nom`,
      firstName: `Prénom`,
      cardNum: `N° de carte Club`,
      phone: `N° de téléphone`,
      date: `Date`,
      status: `Statut`,
      detail: `Détail`
    },
    empty: `Aucun résultat trouvé`
  },
  evenementialExports: {
    head: {
      title: `Export Événements`,
      subtitle: `Mise à disposition des actions réalisées par les opérateurs CCC`
    },
    search: {
      select: `Sélectionner la période souhaitée à exporter :`,
      period: `Période`,
      startDatePlaceholder: `Date de début`,
      endDatePlaceholder: `Date de fin`,
      rangeSeparator: `à`,
      cta: `Exporter`,
      ranges: {
        lastWeek: `Semaine dernière`,
        lastMonth: `Mois dernier`,
        lastThreeMonths: `Trois derniers mois`
      }
    },
    table: {
      columns: {
        date: 'Date',
        filename: 'Nom du fichier',
        action: 'Action'
      },
      empty: '- Aucun événement exporté -',
      downloadCTA: 'Télécharger'
    }
  },
  lotteryPage: {
    head: {
      title: `Prochain tirage au sort`,
      subtitle: `Période du {from} au {to}`,
      lastWinner: `Gagnant de la semaine dernière`
    },
    table: {
      title: `Historique des gagnants`,
      explain: `Clubistes vainqueurs du tirage au sort`,
      search: `Saisissez un nom ou un n° de carte Club`,
      empty: `Aucune gagnant trouvé`,
      column: {
        period: {
          label: `Période`,
          value: `Du {from} au {to}`
        },
        date: `Date du tirage`,
        button: `Voir le profil`
      }
    },
    countdown: {
      days: 'jours',
      hours: 'heures',
      minutes: 'minutes'
    }
  },
  clubistHistory: {
    title: `Historique`,
    tables: {
      cashback: {
        title: `Cashback`,
        column: {
          sub: `Retiré`,
          add: `Ajouté`,
          detail: {
            label: `Détails`,
            value: `Effectué le {date}`
          },
          status: {
            reset: `Remise à zéro`,
            wait: `En attente`,
            paid: `Versé`,
            expired: `Expiré`,
            refused: `Refusé`
          }
        },
        popin: {
          amount: {
            title: `Montant expiré`,
            titleTransfered: `Montant versé`,
            value: `{amount} dhs`
          },
          author: {
            title: `Action initiée par`,
            value: {
              clubist: `Le clubiste`,
              callcenter: `Le Call Center`,
              market: `Le Marketing`,
              monetic: `Monetic`,
              null: `Inconnu`
            }
          }
        }
      },
      transactions: {
        title: `Transactions`,
        column: {
          date: `Date/Heure`,
          object: `Natures`,
          range: `Gammes`,
          packing: `Emballage (L)`,
          price: `Montant (dhs)`,
          station: `Stations`
        }
      },
      events: {
        title: `Événements`,
        column: {
          date: `Date/Heure`,
          user: `Opérateur`,
          type: `Typologie`,
          detail: `Détail`
        }
      }
    }
  },
  api: {
    serverError: `Erreur du serveur, veuillez réessayer plus tard`,
    dataUndefined: `Aucune données retournées par le serveur, veuillez réessayer plus tard`,
    cardNotFound: `Aucune carte ne correspond à ce numéro`,
    cardAlreadyUsed: `Cette carte est déjà utilisée`
  },
  events: actionEvents
}
