<template>
  <router-link class="el-button" :to="to" :class="{ ['el-button--' + type] : type, 'is-plain': plain }">
    <span>
      <slot />
    </span>
  </router-link>
</template>

<script>
export default {
  props: {
    to: Object,
    type: {
      type: String,
      default: null
    },
    plain: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.el-button {
  text-decoration: none;
}
</style>
