import Vue from 'vue'
import { isProdEnv, isStagingEnv, isPreprodEnv } from './pure/env'
import { version, name } from '../package.json'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import Bowser from 'bowser'

function getSentryDSN () {
  try {
    const sentryclirc = require('../.sentryclirc').default
    const dsn = (sentryclirc.match(/\n *dsn *= *(.+)(?: )*/i) || [])[1]
    return dsn && dsn.length > 0 ? dsn.trim() : undefined
  } catch (error) {
    return undefined
  }
}

// Your Sentry key
const dsn = getSentryDSN()

if (!dsn) {
  console.error('DSN Sentry not configured, please accept the merge request')

// Load Sentry if production, preproduction or staging environment
} else if (isProdEnv() || isStagingEnv() || isPreprodEnv()) {
  const browser = Bowser.getParser(window.navigator.userAgent)

  // Init sentry only in the follow browser scope
  const isValidBrowser = browser.satisfies({
    ie: '>=11',
    edge: '>=17',
    chrome: '>=69',
    firefox: '>=62',
    safari: '>=11.1'
  })

  if (isValidBrowser) {
    Sentry.init({
      dsn,
      integrations: [
        // Add vue.js data in report
        new Integrations.Vue({ Vue, attachProps: true })
      ],
      environment: process.env.NODE_ENV,
      release: name + '@' + version
    })

    console.log('Sentry enabled on', browser.getBrowserName())
  }
}
