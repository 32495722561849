<template>
  <span v-if="value" class="round-badge">
    {{ value }}
  </span>
</template>

<script>
export default {
  props: ['value']
}
</script>

<style lang="scss" scoped>
.round-badge {
  align-self: center;
  background: $blue;
  color: white;
  text-align: center;
  border-radius: 1em;
  padding: 0.25em 0.6em 0.25em 0.55em;
  margin: -0.2em 0 0 0.5em;
  font-weight: 300;
  font-size: 0.85em;
}
</style>
