import ApiRequest from '@/pure/api-requests'
import { decodeFile, encodeFile } from '@/pure/api-parser'

import {
  media as requestMedia,
  prePaidCardCreationScheduleClaimExport as requestPrePaidCardCreationScheduleClaimExport,
  prepaidCardCreationScheduleCashbackAndDeliveryExport as requestPrepaidCardCreationScheduleCashbackAndDeliveryExport
} from '../../../graphql-sdl/requests-backoffice.graphql'

const apiRequest = new ApiRequest()
const categories = [
  'PREPAID_CARD_CREATE',
  'CASHBACK_PAID',
  'PREPAID_CARD_BLOCK',
  'PREPAID_CARD_EXPORT',
  'CASHBACK_COMPLAIN',
  'STATUS_MODIFIER',
  'EVENT_EXPORT',
  'TRANSACTION',
  'GAUGE'
]

/** @type import('vuex/types').Module<state, any> */
const module = {
  namespaced: true,

  actions: {
    uploadFile (_, { file, url }) {
      return fetch(url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type
        }
      })
    },

    requestPrePaidCardExport (_) {
      return apiRequest.mutation(requestPrePaidCardCreationScheduleClaimExport)
    },

    requestCashbackDeliveryExport (_) {
      return apiRequest.mutation(requestPrepaidCardCreationScheduleCashbackAndDeliveryExport)
    },

    getUploadFileUrl (_, { category, file }) {
      if (categories.indexOf(category) < 0) {
        throw new Error('The category ' + category + ' not exist')
      }

      const payload = {
        mediaEdit: {
          method: 'PUT',
          mediaContent: category,
          file: {
            name: 'TO_1M/' + category + '/' + file.name,
            type: file.type
          }
        }
      }

      return apiRequest.mutation(requestMedia, payload)
        .then(data => decodeFile(data.media))
    },

    downloadFile ({ dispatch }, { category, file }) {
      if (categories.indexOf(category) < 0) {
        throw new Error('The category ' + category + ' not exist')
      }

      return dispatch('getDownloadFileUrl', { category, file })
        .then(({ storageUploadUrl }) => storageUploadUrl)
        .then(fetch)
        .then(resp => resp.blob())
        .then(blob => {
          // IE11 fix
          if (navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(blob, file.name)
          } else {
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = url
            a.download = file.name
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            window.URL.revokeObjectURL(url)
          }
        })
    },

    getDownloadFileUrl (_, { category, file }) {
      if (categories.indexOf(category) < 0) {
        throw new Error('The category ' + category + ' not exist')
      }

      const payload = {
        mediaEdit: {
          method: 'GET',
          mediaContent: category,
          file: encodeFile(file)
        }
      }

      return apiRequest.mutation(requestMedia, payload)
        .then(data => decodeFile(data.media))
    },

    completeFile (_, { category, file }) {
      if (categories.indexOf(category) < 0) {
        throw new Error('The category ' + category + ' not exist')
      }

      const payload = {
        mediaEdit: {
          method: 'PATCH',
          mediaContent: category,
          file: encodeFile(file)
        }
      }

      return apiRequest.mutation(requestMedia, payload)
        .then(data => decodeFile(data.media))
    }
  }
}

export default module
