import ApiRequest from '@/pure/api-requests'
import { decodeMedia } from '@/pure/api-parser'

import {
  medias as requestMedias
} from '../../../graphql-sdl/requests-backoffice.graphql'

const apiRequest = new ApiRequest()

/** @type import('vuex/types').Module<state, any> */
const module = {
  namespaced: true,

  actions: {
    getMedia (_, { category }) {
      const payload = {
        filterMedia: {
          mediaContent: category
        }
      }

      return apiRequest.query(requestMedias, payload)
        .then(data => (data.medias || []).map(decodeMedia))
    }
  }
}

export default module
