import ApiRequest from '@/pure/api-requests'
import { decodeMediaPrepaidCard } from '@/pure/api-parser'

import {
  mediaRequest as claimMedia,
  medias as requestMedias
} from '../../../graphql-sdl/requests-backoffice.graphql'

const apiRequest = new ApiRequest()

/** @type import('vuex/types').Module<state, {}> */
const module = {
  namespaced: true,

  state: { },

  actions: {
    claimMedia (_, {
      startDate = new Date(),
      endDate = new Date(),
      clubistId = null,
      category
    } = {}) {
      const payload = {
        mediaRequest: {
          startDate,
          endDate,
          mediaContent: category
        }
      }

      if (clubistId) {
        payload.mediaRequest.clubistId = clubistId
      }

      return apiRequest.mutation(claimMedia, payload)
    },

    getMediaList (_, { category, clubistId = null }) {
      const payload = {
        filterMedia: {
          mediaContent: Array.isArray(category) ? category : [category]
        }
      }

      if (clubistId) {
        payload.filterMedia.clubistId = clubistId
      }

      return apiRequest.query(requestMedias, payload)
        .then((data) => {
          return data && data.medias
            ? data.medias.map(decodeMediaPrepaidCard)
            : []
        })
    }
  }
}

export default module
