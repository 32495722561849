<template>
  <div id="app" :class="{'logged-in': $route.meta && $route.meta.requiresAuth}">
    <SiteHeader v-if="$route.meta && $route.meta.requiresAuth"></SiteHeader>
    <router-view class="content" />
  </div>
</template>

<script>
import SiteHeader from '@/components/SiteHeader.vue'
import ApiRequest from '@/pure/api-requests.js'

export default {
  components: {
    SiteHeader
  },

  created () {
    // Test version on start
    this.$store.dispatch('checkUpdate')
      .then(success => {
        if (success) {
          console.log('No updates required')
        } else {
          console.warn('Client update required')
        }
      })
      .catch(error => console.error(error.message))

    // Test version if chunk error
    window.addEventListener('error', error => {
      if (error.message.indexOf(`Unexpected token '<'`) > -1) {
        this.$store.dispatch('checkUpdate')
          .then(success => {
            if (success) {
              console.log('No updates required')
            } else {
              console.warn('Client update required')
            }
          })
          .catch(error => console.error(error.message))
      }
    })

    new ApiRequest().on('access-denied', this.accesDenied)

    this.$store.dispatch('updateServerTime')
      .catch(() => console.warn('Can not initialize times'))

    // Login test
    this.$store.dispatch('refreshUser')
      .then(() => {
        if (this.$route.name === 'home') {
          // Landing page of monetic is different than other roles
          if (this.$store.getters.isMonetic) {
            return this.$router.push({ name: 'createTotalCard' })
          } else if (this.$store.getters.isAdministrator) {
            return this.$router.push({ name: 'admin' })
          } else {
            return this.$router.push({ name: 'search' })
          }
        }
      })
      .catch(error => console.error(error.message))
  },

  destroyed () {
    new ApiRequest().off('access-denied', this.accesDenied)
  },

  methods: {
    accesDenied () {
      if (this.$route.meta && this.$route.meta.requiresAuth) {
        this.$router.push({ name: 'home' })
          .catch(error => console.error(error.message))
      }
    }
  }
}
</script>

<style lang="scss">
#app {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: $dark-grey;
  overflow-y: auto;

  &.logged-in {
    background: $light-grey;

    .content {
      top: 90px;

      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      overflow-y: scroll;
      scroll-behavior: smooth;
      overflow-scrolling: touch;
      padding: 25px;
    }
  }
}
</style>
