import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import ApiRequest from '@/pure/api-requests'

import lotteryModule from './modules/lottery'
import mediaModule from './modules/media'
import marketingModule from './modules/marketing'
import historyModule from './modules/history'
import exportsModule from './modules/exports'
import fileModule from './modules/file'
import adminModule from './modules/admin'
import { version } from '../../package.json'

import {
  decodeClubistData,
  encodeClubistData,
  encodeClubistDataFlexible,
  decodeStationData,
  decodeCashbackComplain,
  decodeUserData,
  decodeClubCardData,
  encodeFile
} from '@/pure/api-parser'

import {
  clubist as requestClubist,
  stations as requestStations,
  clubistEdit as requestEditClubist,
  prePaidCardClaim as requestClaimPrePaidCard,
  prePaidCardBlock as requestBlockPrePaidCard,
  cashbackClaimUnlock as requestClaimUnlockCashback,
  info as requestInfos,
  logout as requestLogout,
  user as requestUser,
  passwordForgot as requestPasswordForgot,
  clubCards as requestClubCards,
  passwordReset as requestPasswordReset
} from '../../graphql-sdl/requests-clubist.graphql'

import {
  backOfficeLogin as requestBackofficeLogin,
  clubistSearch as requestClubistSearch,
  clubistCreate as requestClubistCreate,
  cashbackComplain as requestCashbackComplain,
  cashbackComplains as requestCashbackComplainList,
  cashbackComplainProcess as requestCashbackComplainProcess
} from '../../graphql-sdl/requests-backoffice.graphql'

const apiRequest = new ApiRequest()

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ['user']
    })
  ],

  modules: {
    lottery: lotteryModule,
    media: mediaModule,
    marketing: marketingModule,
    history: historyModule,
    file: fileModule,
    exports: exportsModule,
    admin: adminModule
  },

  state: {
    user: null,
    search: {},
    stations: [],
    times: {}
  },

  getters: {
    isCallCenter: state => state.user && state.user.isCallCenter,
    isMarketing: state => state.user && state.user.isMarketing,
    isMonetic: state => state.user && state.user.isMonetic,
    isDirector: state => state.user && state.user.isDirector,
    isAdministrator: state => state.user && state.user.isAdministrator,
    isClubist: state => state.user && !state.user.isCallCenter && !state.user.isMarketing && !state.user.isMonetic && !state.user.isDirector && !state.user.isAdministrator,

    searchFirstName: state => state.search.firstName || '',
    searchLastName: state => state.search.lastName || '',
    searchPhone: state => state.search.phone || '',
    searchClubCardNum: state => state.search.clubCardNum || '',
    searchPrepaidCardNum: state => state.search.prepaidCardNum || '',

    isLoggedIn: state => !!state.user,

    clientDelay: state => state.times.server && state.times.client ? (state.times.server - state.times.client) : 0
  },

  mutations: {
    user (state, user) {
      state.user = user
    },
    login (state, user) {
      state.user = user
    },
    logout (state) {
      state.user = {}
    },
    times (state, { server, client }) {
      state.times = {
        server,
        client
      }
    },
    search (state, { firstName = null, lastName = null, phone = null, clubCardNum = null, prepaidCardNum = null }) {
      const search = {
        firstName,
        lastName,
        phone,
        clubCardNum,
        prepaidCardNum
      }

      state.search = search
    },
    stations (state, stations) {
      state.stations = stations
    }
  },

  actions: {
    checkUpdate () {
      // Check updates
      const randomSlug = Math.round(Math.random() * 0xFFFFFFFF).toString(36)
      const versionFilename = '/version-' + version.replace(/\./g, '-') + '.json'
      return fetch(versionFilename + '?r=' + randomSlug)
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            return true
          } else {
            throw new Error()
          }
        })
        .catch(() => {
          window.location.reload(true)
          return false
        })
    },
    refreshUser ({ commit }) {
      return apiRequest.query(requestUser)
        .then(data => {
          const user = decodeUserData(data.user)
          commit('user', user)
          return user
        })
    },
    updateServerTime ({ commit }) {
      return apiRequest.query(requestInfos)
        .then(data => {
          try {
            console.log('Server version:', data.info.version)

            // Get Morocco timezone offset
            const moroccoTimezoneOffsetStr = data.info.moroccoDateTime.substring(26)
            const moroccoTimezoneOffset = Number(moroccoTimezoneOffsetStr.split(':')[0]) + Number(moroccoTimezoneOffsetStr.split(':')[1]) / 60

            const server = new Date(data.info.currentDateTime).getTime() + moroccoTimezoneOffset * 60 * 60 * 1000
            const client = Date.now()
            commit('times', { server, client })
            return server
          } catch (error) {
            throw error
          }
        })
    },
    resetClubistPassword (_, { cardNum }) {
      return apiRequest.mutation(requestPasswordForgot, {
        passwordForgot: {
          idClubCard: cardNum
        }
      })
        .then(data => data.passwordForgot === true)
    },
    resetUserPassword (_, { email }) {
      return apiRequest.mutation(requestPasswordForgot, {
        passwordForgot: {
          email
        }
      })
        .then(data => data.passwordForgot === true)
    },
    updateUserPasswors (_, { hash, password }) {
      return apiRequest.mutation(requestPasswordReset, {
        passwordReset: {
          hash,
          password,
          recaptcha: 'disabled'
        }
      })
    },
    login ({ dispatch }, { email, password }) {
      const payload = {
        user: {
          email,
          password
        }
      }

      return apiRequest.mutation(requestBackofficeLogin, payload)
        .then(() => {
          return dispatch('refreshUser')
        })
    },
    getUnlinkedClubCard (_, { cardNum }) {
      const payload1 = {
        clubCardFilter: {
          clubCardId: cardNum
        }
      }
      const payload2 = {
        clubistSearch: {
          clubCardId: cardNum
        }
      }

      return Promise.all([
        apiRequest.query(requestClubCards, payload1),
        apiRequest.query(requestClubistSearch, payload2)
      ])
        .then(([ cardData, clubistData ]) => {
          if (cardData.clubCards.length < 1) {
            throw new Error('api.cardNotFound')
          }
          if (clubistData.clubistSearch.length > 0) {
            throw new Error('api.cardAlreadyUsed')
          }
          return decodeClubCardData(cardData.clubCards[0])
        })
    },
    createClubist (_, clubistData) {
      return new Promise((resolve, reject) => {
        try {
          const payload = {
            clubist: encodeClubistDataFlexible(clubistData)
          }
          resolve(payload)
        } catch (error) {
          reject(error)
        }
      })
        .then(payload => {
          return apiRequest.mutation(requestClubistCreate, payload)
            .then(data => {
              return decodeClubistData(data.clubistCreate)
            })
        })
    },
    cashbackComplaintProcess (_, { id, clubistId, isAccepted, amount = null }) {
      const payload = {
        cashbackComplainProcess: {
          clubistId,
          cashbackComplainId: id,
          state: isAccepted ? 'VALIDATED' : 'REFUSED'
        }
      }

      if (amount !== null) {
        payload.cashbackComplainProcess.amount = amount
      }

      return apiRequest.mutation(requestCashbackComplainProcess, payload)
    },
    cashbackComplaint ({ dispatch }, { clubistId = null, file = null, comment }) {
      const cashbackComplain = {
        clubistId
      }

      if (file) {
        cashbackComplain.file = encodeFile(file)
      }

      cashbackComplain.comment = comment || ''
      return apiRequest.mutation(requestCashbackComplain, { cashbackComplain })
        .then(() => dispatch('refreshClubist', { id: clubistId }))
    },
    cashbackComplaintList (_, { clubistId = null }) {
      const payload = { }

      if (clubistId) {
        payload.clubistId = clubistId
      }

      return apiRequest.query(requestCashbackComplainList, payload)
        .then(({ cashbackComplains }) => {
          const list = !cashbackComplains ? [] : (Array.isArray(cashbackComplains) ? cashbackComplains : [cashbackComplains])
          return list.map(decodeCashbackComplain)
        })
    },
    claimUnlockCashback ({ dispatch }, { clubistId = null }) {
      const cashbackClaimUnlock = {
        clubistId
      }

      return apiRequest.mutation(requestClaimUnlockCashback, { cashbackClaimUnlock })
        .then(() => dispatch('refreshClubist', { id: clubistId }))
    },
    claimPrepaidCard ({ dispatch }, { clubistId = null, station = null, address = null }) {
      const prePaidCard = {
        clubistId
      }

      if (station) {
        prePaidCard.stationId = station.id
        prePaidCard.name = station.name
        prePaidCard.address = station.street
        prePaidCard.addressComplement = station.complement
        prePaidCard.addressDistrict = station.district
        prePaidCard.addressCity = station.city
        prePaidCard.addressZipcode = station.zipcode
      } else if (address) {
        prePaidCard.address = address.street
        prePaidCard.addressComplement = address.complement
        prePaidCard.addressDistrict = address.district
        prePaidCard.addressCity = address.city
        prePaidCard.addressZipcode = address.zipcode
      }

      return apiRequest.mutation(requestClaimPrePaidCard, { prePaidCard })
        .then(() => dispatch('refreshClubist', { id: clubistId }))
    },
    blockPrepaidCard ({ dispatch }, { clubistId = null, station = null, address = null }) {
      const prePaidCard = {
        clubistId
      }

      if (station) {
        prePaidCard.stationId = station.id
        prePaidCard.name = station.name
        prePaidCard.address = station.street
        prePaidCard.addressComplement = station.complement
        prePaidCard.addressDistrict = station.district
        prePaidCard.addressCity = station.city
        prePaidCard.addressZipcode = station.zipcode
      } else if (address) {
        prePaidCard.address = address.street
        prePaidCard.addressComplement = address.complement
        prePaidCard.addressDistrict = address.district
        prePaidCard.addressCity = address.city
        prePaidCard.addressZipcode = address.zipcode
      }

      return apiRequest.mutation(requestBlockPrePaidCard, { prePaidCard })
        .then(() => dispatch('refreshClubist', { id: clubistId }))
    },
    editClubist (_, clubistData) {
      return new Promise((resolve, reject) => {
        try {
          const payload = {
            clubist: encodeClubistData(clubistData)
          }
          resolve(payload)
        } catch (error) {
          reject(error)
        }
      })
        .then(payload => {
          return apiRequest.mutation(requestEditClubist, payload)
            .then(data => {
              return decodeClubistData(data.clubistEdit)
            })
        })
    },
    refreshClubist (_, { id }) {
      return apiRequest.query(requestClubist, { id })
        .then(data => {
          return decodeClubistData(data.clubist)
        })
    },
    updateStations ({ commit }) {
      return apiRequest.query(requestStations)
        .then(data => {
          try {
            commit('stations', data.stations.map(decodeStationData))
            return true
          } catch (error) {
            throw error
          }
        })
    },
    searchClubist ({ commit }, { firstName = null, lastName = null, phone = null, clubCardNum = null, prepaidCardNum = null }) {
      commit('search', { firstName, lastName, phone, clubCardNum, prepaidCardNum })

      const payload = {
        clubistSearch: {
          firstName,
          lastName,
          phoneNumber: phone,
          clubCardId: clubCardNum,
          prePaidCardId: prepaidCardNum
        }
      }

      Object.keys(payload.clubistSearch).forEach(key => {
        if (payload.clubistSearch[key] === null || payload.clubistSearch[key] === '') {
          delete payload.clubistSearch[key]
        }
      })

      return apiRequest.query(requestClubistSearch, payload)
        .then(data => {
          try {
            return data.clubistSearch.map(decodeClubistData)
          } catch (error) {
            throw error
          }
        })
    },
    logout ({ commit, dispatch }) {
      return apiRequest.mutation(requestLogout)
        .finally(() => {
          commit('logout')
        })
    }
  }
})
