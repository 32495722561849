import ApiRequest from '@/pure/api-requests'

import {
  adminGenerateClubCard
} from '../../../graphql-sdl/requests-backoffice.graphql'

const apiRequest = new ApiRequest()

/** @type import('vuex/types').Module<state, any> */
const module = {
  namespaced: true,

  actions: {
    generateClubCard () {
      return apiRequest.mutation(adminGenerateClubCard, {})
        .then(data => (data.adminGenerateClubCard || {}))
    }
  }
}

export default module
