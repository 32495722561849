import ApiRequest from '@/pure/api-requests'

import {
  decodeHistoryCashback,
  decodeHistoryTransaction,
  decodeHistoryEvents,
  decodeGaugeHistory
} from '@/pure/api-parser'

import {
  historiesTransactions as requestHistoriesTransactions,
  historiesCashbacks as requestHistoriesCashbacks,
  historiesEvents as requestHistoriesEvents,
  historiesGauge as requestHistoriesGauge
} from '../../../graphql-sdl/requests-backoffice.graphql'

const apiRequest = new ApiRequest()

/** @type import('vuex/types').Module<{}, {}> */
const module = {
  namespaced: true,

  state: { },

  actions: {
    getCashbacks (_, { clubistId }) {
      const payload = {
        historyFilter: {
          clubistId
        }
      }

      return apiRequest.query(requestHistoriesCashbacks, payload)
        .then(data => (data.histories.cashbacks || []).map(decodeHistoryCashback))
    },
    getGaugeHistories (_, { clubistId }) {
      const payload = {
        historyFilter: {
          clubistId
        }
      }

      return apiRequest.query(requestHistoriesGauge, payload)
        .then(data => (data.histories.cashbacksDetails || []).map(decodeGaugeHistory))
    },
    getTransactions (_, { clubistId }) {
      const payload = {
        historyFilter: {
          clubistId
        }
      }

      return apiRequest.query(requestHistoriesTransactions, payload)
        .then(data => (data.histories.transactions || []).map(decodeHistoryTransaction))
    },
    getEvents (_, { clubistId }) {
      const payload = {
        historyFilter: {
          clubistId
        }
      }
      return apiRequest.query(requestHistoriesEvents, payload)
        .then(data => (data.histories.events || []).map(decodeHistoryEvents))
    }
  }
}

export default module
