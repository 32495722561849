<template>
  <div v-loading="isLoading" class="login-page page">
    <div class="container">
      <el-row type="flex" justify="center" class="row-logo">
        <el-row>
          <img src="@/assets/images/total-energies-logo-2x.png" alt width="180" height="144"/>
        </el-row>
      </el-row>
      <el-row type="flex" align="middle" justify="center">
        <el-col :span="9">
          <el-row class="align-center">
            <img src="@/assets/images/card-login.png" alt="Club Card" />
          </el-row>
        </el-col>
        <el-col :span="9">
          <el-row class="row-with-form align-center">
            <h1>Connexion</h1>
            <el-form ref="loginForm" :model="user" :rules="rules" label-position="top" @submit.native.prevent>
              <el-form-item ref="email" label="identifiant" prop="email">
                <el-input data-cy="email" placeholder="Email" v-model="user.email" @keyup.enter.native="submit" autocomplete="email"></el-input>
              </el-form-item>
              <el-form-item label="Mot de passe" prop="password">
                <el-input data-cy="password" ref="pass" placeholder="Mot de passe" v-model="user.password" show-password autocomplete="current-password" @keyup.enter.native="submit"></el-input>
              </el-form-item>
            </el-form>
            <el-row type="flex" justify="center">
              <el-button data-cy="signin" type="danger" @click="submit">Se connecter</el-button>
            </el-row>
            <button class="reset-pass" @click="resetPassword()">Mot de passe oublié ?</button>

          </el-row>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { emailRule, createRequired } from '@/data/input-rules'

export default {
  name: 'login',
  data () {
    return {
      isLoading: false,
      user: {
        email: '',
        password: ''
      },
      isResetPassword: false
    }
  },
  computed: {
    rules () {
      return {
        email: [
          ...emailRule,
          createRequired('email')
        ],
        password: this.isResetPassword ? [] : [
          createRequired('mot de passe')
        ]
      }
    }
  },
  created () {
    this.$store.dispatch('checkUpdate')
      .catch(error => console.error(error.message))
  },
  methods: {
    resetPassword () {
      this.isResetPassword = true
      this.user.password = ''
      this.$refs.loginForm.clearValidate()
      this.$nextTick(() => {
        this.$refs.loginForm.validate((valid) => {
          if (valid) {
            this.$store.dispatch('resetUserPassword', {
              email: this.user.email
            })
              .then(() => {
                this.$refs.loginForm.clearValidate()
                this.$message({
                  type: 'success',
                  message: 'Demande de renouvellement de mot de passe envoyée'
                })
              })
              .catch(error => this.$message.error(this.$t(error.message)))
          }
        })
      })
    },
    submit () {
      this.isResetPassword = false
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.isLoading = true
          this.$store.dispatch('login', { ...this.user })
            .then(user => {
              if (user.isCallCenter || user.isMarketing || user.isDirector) {
                this.$router.push({ name: 'search' })
                  .catch(error => console.error(error.message))
              } else if (user.isAdministrator) {
                this.$router.push({ name: 'admin' })
                  .catch(error => console.error(error.message))
              } else if (user.isMonetic) {
                this.$router.push({ name: 'createTotalCard' })
                  .catch(error => console.error(error.message))
              }
            })
            .catch(error => this.$message.error(this.$t(error.message)))
            .finally(() => {
              this.isLoading = false
            })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  background-color: transparent;
  h1 {
    font-size: 24px;
    font-weight: 500;
  }
  .el-form {
    text-align: left;
    margin-bottom: 25px;
  }
  .el-button {
    margin-bottom: 25px;
  }
}
.row-logo {
  margin-bottom: 5%;
}
.row-with-form {
  max-width: 250px;
  margin: 0 auto;
}
.container {
  margin: 0; // IE11 fix
}
.reset-pass {
  border: none;
  font-size: 16px;
  color: $dark-grey;
  text-decoration: underline;
}
</style>
