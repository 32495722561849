import ApiRequest from '@/pure/api-requests'
import { decodeLottery } from '@/pure/api-parser'

/** @type import('vuex/types').Module<any, any> */
import {
  lotteries as requestLotteries
} from '../../../graphql-sdl/requests-backoffice.graphql'

const apiRequest = new ApiRequest()

/** @type import('vuex/types').Module<state, {}> */
const module = {
  namespaced: true,

  state: { },

  actions: {
    getLotteries (_, { count = null } = {}) {
      const payload = {
        lotteryFilter: {
          isConfirmed: true
        }
      }

      if (count !== null) {
        payload.lotteryFilter.count = count
      }

      return apiRequest.query(requestLotteries, payload)
        .then(data => data.lotteries.map(decodeLottery))
    },

    getLastLotteries () {
      const payload = {
        lotteryFilter: {
          isConfirmed: true,
          count: 1
        }
      }

      return apiRequest.query(requestLotteries, payload)
        .then(data => data.lotteries.map(decodeLottery))
    }
  }
}

export default module
