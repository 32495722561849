import Vue from 'vue'
import Router from 'vue-router'
import Login from './views/Login.vue'
import store from './store'

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Login
  },
  // Gate redirection
  {
    path: '/login',
    redirect: {
      name: 'home'
    }
  },
  // Reset password
  {
    path: '/forgot_password/:hash',
    name: 'resetPassword',
    component: () => import(/* webpackChunkName: "global" */ './views/ResetPassword.vue')
  },
  {
    path: '/clubistes',
    name: 'search',
    component: () => import(/* webpackChunkName: "global" */ './views/Search.vue'),
    meta: {
      requiresAuth: ['isCallCenter'],
      menuIndex: 0
    }
  },
  {
    path: '/clubistes/:clubistId([0-9a-z-]+)',
    name: 'clubist',
    component: () => import(/* webpackChunkName: "clubist" */ './views/clubist/index.vue'),
    meta: {
      requiresAuth: ['isCallCenter'],
      menuIndex: 0
    },
    children: [
      {
        path: 'informations',
        name: 'clubistProfile',
        component: () => import(/* webpackChunkName: "clubist" */ './views/clubist/Profile.vue'),
        meta: {
          requiresAuth: ['isCallCenter'],
          menuIndex: 0
        }
      },
      {
        path: 'historique',
        name: 'clubistHistory',
        component: () => import(/* webpackChunkName: "clubist" */ './views/clubist/History.vue'),
        meta: {
          requiresAuth: ['isCallCenter'],
          menuIndex: 0
        }
      },
      {
        path: 'cashback',
        name: 'clubistCashback',
        component: () => import(/* webpackChunkName: "clubist" */ './views/clubist/Cashback.vue'),
        meta: {
          requiresAuth: ['isCallCenter'],
          menuIndex: 0
        }
      },
      {
        path: 'a-faire',
        name: 'clubistTodo',
        component: () => import(/* webpackChunkName: "market" */ './views/clubist/Requests.vue'),
        meta: {
          requiresAuth: ['isMarketing'],
          menuIndex: 0
        }
      }
    ]
  },
  {
    path: '/demandes-call-center',
    name: 'cCRequests',
    component: () => import(/* webpackChunkName: "market" */ './views/cCRequests/index.vue'),
    meta: { requiresAuth: ['isMarketing'] },
    children: [
      {
        path: 'reclamation-de-cashback',
        name: 'cCRequestsCashbackClaim',
        component: () => import(/* webpackChunkName: "market" */ './views/cCRequests/CashbackClaim.vue'),
        meta: {
          requiresAuth: ['isMarketing'],
          menuIndex: 1
        }
      },
      {
        path: 'changement-de-statut',
        name: 'cCStatusRequests',
        component: () => import(/* webpackChunkName: "market" */ './views/cCRequests/StatusRequests.vue'),
        meta: {
          requiresAuth: ['isMarketing'],
          menuIndex: 1
        }
      }
    ]
  },
  {
    path: '/tirage-au-sort',
    name: 'lottery',
    component: () => import(/* webpackChunkName: "global" */ './views/Lottery.vue'),
    meta: {
      requiresAuth: ['isCallCenter'],
      menuIndex: 2
    }
  },
  {
    path: '/envoi-total-card',
    name: 'sendPrepaidCard',
    component: () => import(/* webpackChunkName: "global" */ './views/SendPrepaidCard.vue'),
    meta: {
      requiresAuth: ['isDirector'],
      menuIndex: 3
    }
  },
  {
    path: '/demandes-a-traiter',
    name: 'monetic',
    component: () => import(/* webpackChunkName: "monetic" */ './views/monetic/index.vue'),
    meta: {
      requiresAuth: ['isMonetic'],
      menuIndex: 4
    },
    children: [
      {
        path: 'creation-de-total-card',
        name: 'createTotalCard',
        component: () => import(/* webpackChunkName: "monetic" */ './views/monetic/CreateTotalCard.vue'),
        meta: {
          requiresAuth: ['isMonetic'],
          menuIndex: 4
        }
      },
      {
        path: 'versement-de-cashback',
        name: 'claimCashback',
        component: () => import(/* webpackChunkName: "monetic" */ './views/monetic/ClaimCashback.vue'),
        meta: {
          requiresAuth: ['isMonetic'],
          menuIndex: 4
        }
      },
      {
        path: 'blockage-total-card',
        name: 'banTotalCard',
        component: () => import(/* webpackChunkName: "monetic" */ './views/monetic/BlockTotalCard.vue'),
        meta: {
          requiresAuth: ['isMonetic'],
          menuIndex: 4
        }
      }
    ]
  },
  {
    path: '/exports-evenementiels',
    name: 'exportsEvenementiels',
    component: () => import(/* webpackChunkName: "evenementialExports" */ './views/EvenementialExports.vue'),
    meta: {
      requiresAuth: ['isDirector'],
      menuIndex: 5
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "admin" */ './views/Admin.vue'),
    meta: {
      requiresAuth: ['isAdministrator'],
      menuIndex: 0
    }
  },
  {
    path: '*',
    name: 'error404',
    component: () => import(/* webpackChunkName: "global" */ './views/Error404.vue')
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Navigation guard
router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.requiresAuth) {
    const accessGranted = to.meta.requiresAuth.reduce((accessGranted, label) => accessGranted || store.getters[label], false)
    if (!accessGranted) {
      if (store.getters.isMonetic) {
        return next({ name: 'createTotalCard' })
      } else if (store.getters.isCallCenter) {
        return next({ name: 'search' })
      } else if (store.getters.isMarketing) {
        return next({ name: 'cCRequestsCashbackClaim' })
      } else if (store.getters.isDirector) {
        return next({ name: 'exportsEvenementiels' })
      }

      return next({ name: 'home' })
    }
  }

  next()
})

export { routes }

export default router
